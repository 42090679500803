import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { edgeToBody, edgeToPage, edgeToPostPreview } from "../lib/utils"
import { graphql } from "gatsby"
import Page from "../components/sections/Page"
import SliceZone from "../components/prismic/SliceZone"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { Navigation } from "../components/ui/Navigation"
import { LeadCapturePopup } from "../components/shared/LeadCapturePopup"
import { leadCapturePopupVariables } from "../components/shared/LeadCapturePopup/constants"

const PagePage = ({ data }) => {
  const page = edgeToPage({ edge: data.main.edges[0] })
  const { seo, image } = page
  const body = edgeToBody({ edge: data.main.edges[0] })
  const recentPosts = data.more.edges.map(edge => edgeToPostPreview({ edge }))
  const leadCapturePopup = leadCapturePopupVariables.find(
    item => item.url === page.url
  )

  return (
    <Layout useKameleoon={leadCapturePopup}>
      <SEO image={image} {...seo} />
      <Navigation isBannerShown={!page.hideBanner} />
      <Page {...page} />
      <SliceZone sliceZones={body} posts={recentPosts} />
      {leadCapturePopup && (
        <LeadCapturePopup
          experiment={leadCapturePopup.experiment}
          variant={leadCapturePopup.variant}
          storageKey={leadCapturePopup.storageKey}
          url
          segmentKey={leadCapturePopup.segmentKey}
          image={leadCapturePopup.image}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query PageQuery($id: String, $category: [String]) {
    main: allPrismicPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          _previewable
          data {
            body {
              ...PageHeroHeaderSlice
              ...PageTextRightSlice
              ...PageCallOutBoxSlice
              ...PageFeatureTextLeftSlice
              ...PageCtaButtonSlice
              ...PageFeatureTextRightSlice
              ...PageTextLeftSlice
              ...PageThreeColumnsSlice
              ...PageFourColumnsSlice
              ...PageLargeQuoteSlice
              ...PageVideoSlice
              ...PageImageSlice
              ...PageImageWithTextSlice
              ...PageFullImageWithTextOverlaySlice
              ...PageTextSlice
              ...PageNewsLinksSlice
              ...PageSubMenuNavBarSlice
              ...PageSpaceSlice
              ...PageThreeColumnsListSlice
              ...PageTextAndTwoColumnsListSlice
              ...PageTitleAndCopyWithImageSlice
              ...PageButtonsColumnsSlice
              ...PageListOfItemsWithIconSlice
              ...PageImageWithHeaderSlice
              ...PageAvatarWithBioSlice
              ...PageHeroWithBackgroundImageSlice
              ...PageHeroWithCroppedBackgroundImageSlice
              ...PageListOfCardsSlice
              ...PageListOfPercentagesSlice
              ...PageStatsSlice
              ...PageHeaderWithInsuranceLogosSlice
              ...PageHeaderWithRightCtaSlice
              ...PageHeaderWithStepsSlice
              ...PageHeaderWithTwoContentBlocksSlice
              ...PageFAQSlice
              ...PageContentHubTabOverviewSlice
              ...PageContentHubTabCostSlice
              ...PageContentHubHeaderSlice
              ...PageContentHubTabBenefitsSlice
              ...PageContentHubTabMarriageCounselingSlice
              ...PageContentHubEndCapSlice
              ...PageContentHubTabDiniSlice
              ...PageStickyTabSlice
              ...PageContentHubOnlineTreatmentSlice
              ...PageContentHubPlanSlice
              ...PageImageTextRightSlice
              ...PageImageWithListTextLeftSlice
              ...PageMedicationIntroSlice
              ...PageThreeColumnLPSlice
              ...PageOfferingComparisonSlice
              ...PageTestimonialsSlice
            }
            ctatext {
              text
            }
            content {
              html
            }
            ctalink {
              url
              target
            }
            image {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
            title {
              text
            }
            ctasize
            style
            files {
              file {
                url
                uid
                link_type
                raw
              }
            }
            seotitle {
              text
            }
            seodescription {
              text
            }
            seokeywords {
              text
            }
            hide_from_search_engines
            hide_banner
          }
          prismicId
          uid
          id
        }
      }
    }
    more: allPrismicPost(
      limit: 3
      sort: { fields: data___date, order: DESC }
      filter: {
        data: { categories: { elemMatch: { category: { in: $category } } } }
      }
    ) {
      edges {
        node {
          data {
            title {
              text
            }
            date(formatString: "MM/DD/YYYY")
            previewimage {
              gatsbyImageData(placeholder: BLURRED, layout: FIXED, width: 1216)
            }
            categories {
              category
            }
            content {
              text
            }
            body {
              ... on PrismicPostDataBodyBlogText {
                primary {
                  text {
                    text
                  }
                }
              }
            }
          }
          uid
        }
      }
    }
  }
`

export default process.env.GATSBY_ACTIVE_ENV === "production"
  ? PagePage
  : withPrismicPreview(PagePage)
